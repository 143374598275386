.childrenHeight {
  height: 60px;
}

.thbgc-first {
  background: #CCFFCC;
  width: 100%;

  padding: 2px;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

.thbgc-second {

  background: #CCFFCC;
  width: 100%;

  padding: 2px;
}

.thbgc-third {
  background: #FFF2CC;
  width: 100%;

  padding: 2px;
}

.thbgc-fourth {
  background: #CCFFFF;
  width: 100%;
  padding: 2px;
}

.thbgc-fifth {
  background: #FFF2CC;
  width: 100%;
  padding: 2px;
}

.thbgc-fifth-children {
  background: #FFD966;
  width: 100%;
  padding: 2px;
}

.thbgc-sixth {
  background: #FFF2CC;
  width: 100%;
  padding: 2px;
}

.thbgc-seventh {
  background: #CCFFFF;
  width: 100%;
  padding: 2px;
}

.thbgc-eigth {
  background: #f7c455;
  width: 100%;
  padding: 2px;
}

.thbgc-ninth {
  // background: #CCFFCC;
  width: 100%;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

// .ant-table-cell-ellipsis {
//   background: #f7c455 !important;
// }

.thbgc-first-orange {
  background: #f7c455;
  width: 100%;

  padding: 2px;
}

.thbgc-first-yellow {
  background: #f7f195;
  width: 100%;

  padding: 2px;
}

.thbgc-first-purple {
  background: #c798ca;
  width: 100%;

  padding: 2px;
}

.thbgc-first-green {
  background: #b3eeaa;
  width: 100%;

  padding: 2px;
}

.thbgc-first-blue {
  background: #aad3ee;
  width: 100%;

  padding: 2px;
}

.thbgc-first-yellowEnd {
  background: #d6c474;
  width: 100%;

  padding: 2px;
}

.thbgc-first-orangeEnd {
  background: #eeb85a;
  width: 100%;

  padding: 2px;
}


.ant-table-thead>tr>th {
  padding: 0 !important;
  font-weight: 400 !important;
  // color: #ffffff !important;
  // background: rgb(205, 96, 96) !important;
}

/* YourTableStyle.css */

.odd {
  //奇数行
  background: #FFFFFF;
}

.even {
  //偶数行
  background: rgba(228, 230, 238, 0.4);
}

.ant-dropdown-trigger,
.ant-table-filter-trigger {
  margin-right: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  width: 12px !important;
}

.ant-table-cell {
  padding: 2px !important;
  text-align: center;
}

.ant-table th,
td {
  border: 1px solid rgb(0, 0, 0);
}

.ant-table-cell {
  padding: 0px !important;
}

#fontset {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 12px;
  line-height: 1 !important;

}

.ant-layout {
  padding: 0px !important;
}

.ant-space-gap-col-middle {
  column-gap: 5px;
  /* 修改为你想要的间隔大小 */
}

.image-container {
  width: 60px;
  /* 设置容器宽度 */
  height: 60px;
  /* 设置容器高度 */
  // overflow: hidden;
  // display: flex;

  // justify-content: center;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  /* 隐藏溢出部分 */
}

/* 保持原图片的比例 */
.ant-image,
.ant-image-img {
  object-fit: contain;
  max-height: 60px;
  max-width: 60px;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {

  margin-top: -45px;
}

// .ant-table-wrapper,
.ant-spin-container {
  margin-top: 60px;
}

#custom-form {
  font-size: 10px !important;
}

.ant-input {
  font-size: 12px;
}

.ant-form-item .ant-form-item-label label {
  font-size: 12px;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-first::after,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.541);
}