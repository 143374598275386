.treeTitle {
  display: flex;

  .treeBtn {
    display: none;
  }
}

.treeTitle:hover {
  .treeBtn {
    display: flex;
  }
}