.ant-layout {
  height: 100%;
}

.header {
  padding: 0;
}

.ant-menu-item {
  padding-left: 20px !important;
}

.logo {
  width: 100px;
  height: 60px;
  background: url('~@/assets/logo.png') no-repeat center / 100px auto;
  margin-left: 12px !important;
}

.layout-content {
  overflow-y: auto;
}

.user-info {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 20px;
  color: #fff;

  .user-name {
    margin-right: 20px;
  }

  .user-logout {
    display: inline-block;
    cursor: pointer;
  }
}

.ant-layout-header {
  padding: 0 !important;
}