body {
  font-family: 'Open Sans', sans-serif;
}

.thbgc-first {
  background: #CCFFCC;
  width: 100%;

  padding: 2px;
}

.thbgc-second {

  background: #CCFFCC;
  width: 100%;

  padding: 2px;
}

.thbgc-third {
  background: #FFF2CC;
  width: 100%;

  padding: 2px;
}

.thbgc-fourth {
  background: #CCFFFF;
  width: 100%;
  padding: 2px;
}

.thbgc-fifth {
  background: #FFF2CC;
  width: 100%;
  padding: 2px;
}

.thbgc-fifth-children {
  background: #FFD966;
  width: 100%;
  padding: 2px;
}

.thbgc-sixth {
  background: #FFF2CC;
  width: 100%;
  padding: 2px;
}

.thbgc-seventh {
  background: #CCFFFF;
  width: 100%;
  padding: 2px;
}

.thbgc-eigth {
  background: #f7c455;
  width: 100%;
  padding: 2px;
}

.ant-table-container {
  min-width: 600px;
}


// .ant-table-cell-ellipsis {
//   background: #f7c455 !important;
// }

.thbgc-first-orange {
  background: #f7c455;
  width: 100%;

  padding: 2px;
}

.thbgc-first-yellow {
  background: #f7f195;
  width: 100%;

  padding: 2px;
}

.thbgc-first-purple {
  background: #c798ca;
  width: 100%;

  padding: 2px;
}

.thbgc-first-green {
  background: #b3eeaa;
  width: 100%;

  padding: 2px;
}

.thbgc-first-blue {
  background: #aad3ee;
  width: 100%;

  padding: 2px;
}

.thbgc-first-yellowEnd {
  background: #d6c474;
  width: 100%;

  padding: 2px;
}

.thbgc-first-orangeEnd {
  background: #eeb85a;
  width: 100%;

  padding: 2px;
}



/* YourTableStyle.css */

.odd {
  //奇数行
  background: #FFFFFF;
}

.even {
  //偶数行
  background: rgba(228, 230, 238, 0.4);
}



.ant-table-cell {
  padding: 2px !important;
  text-align: center;
}

.ant-table th,
td {
  border: 1px solid rgb(0, 0, 0);
}

.ant-table-cell {
  padding: 0px !important;
}

.quanxian-table {
  margin-top: -60px !important;
}